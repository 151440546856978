.polygon-label {
    position: absolute;
    display: flex;
    text-align: center;
    font-size: 14px;
    background: white;
    color: var(--accent);
    padding: 5px 10px;
    border-radius: 3px;
    font-weight: 500;
    transform: translate(-50%, -50%);
}
