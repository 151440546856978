.quest-header {
    background: var(--background_content);

    &__progress {
        display: flex;
        flex-direction: column;
        padding-top: 30px !important;
        padding-bottom: 7px !important;

        &__label {
            font-size: 15px;
            font-weight: 600;
            letter-spacing: -0.24px;
            color: var(--text_primary);
            margin-bottom: 8px;
        }

        &__line {
            position: relative;
            width: 100%;
            height: 4px;
            border-radius: 2px;
            background: var(--placeholder_icon_background);
            overflow: hidden;

            span {
                height: 100%;
                display: block;
                border-radius: 2px;
                background: var(--accent);
            }
        }
    }

    &__reward {
        display: flex;
        align-items: center;
        padding-top: 9px !important;
        padding-bottom: 16px !important;

        &__item {
            display: flex;
            align-items: center;

            span {
                font-size: 15px;
                line-height: normal;
                letter-spacing: -0.24px;
                color: var(--text_primary);
                margin-left: 8px;
            }

            & + & {
                margin-left: 18px;
            }

            &:last-child {
                margin-left: auto;
            }
        }
    }
}
