.task-item {
    position: relative;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &__wrap {
        position: relative;
        padding-top: 16px;
        padding-bottom: 16px;

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--separator_common);
            height: 1px;
            transform: scaleY(.5);
        }
    }

    &__title {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        letter-spacing: 0.38px;
        color: var(--text_muted);
    }

    &__description {
        margin-top: 5px;
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: -0.24px;
        color: var(--text_primary);
    }

    &__price {
        margin-top: 6px;
        margin-bottom: 12px;
        font-size: 15px;
        line-height: 1.34;
        letter-spacing: -0.24px;
        color: var(--text_secondary);
        display: flex;
        align-items: baseline;

        span {
            margin-right: 4px;
            font-size: 17px;
            font-weight: 600;
            line-height: 1;
            letter-spacing: -0.41px;
            color: var(--dynamic_green);
        }
    }
}
