.quest-group {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &__title {
        padding-top: 15px !important;
        padding-bottom: 14px !important;
        font-size: 13px;
        font-weight: 600;
        line-height: 1.23;
        letter-spacing: -0.08px;
        text-transform: uppercase;
        color: var(--text_secondary);
    }

    &__content {
        color: var(--text_primary);
    }

    .Search {
        background: transparent !important;
        box-shadow: none;
        padding: 0 6px 12px 6px !important;
        height: auto !important;

        &__in {
            overflow: hidden;
        }

        &--ios {
            padding-left: 12px !important;
            padding-right: 12px !important;

            .Search__control {
                background: var(--search_bar_background);
            }

            .Search__after-width {
                opacity: 0;
            }

            .Search__after {
                background: transparent !important;

                &-in {
                    padding-right: 12px !important;
                }

                &:before, &:after {
                    display: none;
                }
            }
        }

        &--android {
            padding-left: 16px !important;
            padding-right: 16px !important;

            .Search__container {
                background: var(--search_bar_background);
            }

            .Search__input {
                color: var(--text_primary);
            }
        }
    }
}

.radio-check {

    .Checkbox {
        padding: 0 !important;
        display: inline-block;
        border-radius: 10px !important;
        margin-right: 8px !important;
        margin-bottom: 8px !important;

        &__icon {
            display: none;
        }

        &__container {
            border-radius: 10px;
            border: solid 1px var(--background_page) !important;
            padding: 6px 12px;
            color: #818c99;
        }

        .Checkbox__input:checked~.Checkbox__container {
            background-color: var(--accent) !important;
            border: solid 1px var(--accent) !important;

            .Checkbox__content {
                color: #fff !important;
            }
        }
    }
}
