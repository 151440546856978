.quest-actions {
    background: var(--background_content);

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: var(--separator_common);
        height: 1px;
        transform: scaleY(.5);
    }

    &__buttons {
        display: flex;

        .Button + .Button {
            margin-left: 8px;
        }
    }

    &__buttons-column {

        .Button + .Button {
            margin-top: 8px;
        }
    }

    &__square-button {
        width: auto !important;
        padding: 0 !important;
    }
}
